export const MAX_CHAR_LIMIT_COMMERCIAL_POST_DESCRIPTION = 380;
export const MAX_CHAR_LIMIT_COMMERCIAL_POST_DESCRIPTION_WITH_IMAGE = 220;

export const MAX_CHAR_LIMIT_CITY_WRITER_REASON = 300;

export const COMPANY_FREE_PACKAGE = 1;
export const COMPANY_LOCAL_PACKAGE = 2;
export const COMPANY_LOCAL_HERO_PACKAGE = 3;
export const COMPANY_LOCAL_SUPERHERO_PACKAGE = 4;

export const INDIVIDUAL_FREE_PACKAGE = 5;
export const INDIVIDUAL_LOCAL_PACKAGE = 6;
export const INDIVIDUAL_LOCAL_HERO_PACKAGE = 7;
export const INDIVIDUAL_LOCAL_SUPERHERO_PACKAGE = 8;

export const BOOSTED_ADS = 0;
export const MARKET_ADS = 1;
export const SUBSCRIPTIONS = 2;
export const BUNDLE_PACKAGES = 3;

export const TAKE_OFFSET = 9;
export const TAGS_TAKE_OFFSET = 15;

export const GOOGLE_DATA_AD_SLOT_ID = '1234567890';

export const CHAT_NOTIFICATION_TYPES = ['chat', 'chat_reply', 'emoji_react'];

export const MAX_FILE_SIZE = 4000000;

export const CHAR_LIMIT_FOR_FIRST_LAST_COMPANY_NICKNAME = 50;

export const VAT_PERCENTAGE = 8.1;

export const LOCALES = ['en', 'de', 'fr'];

export const GOOGLE_DATA_AD_SLOT_ID_TOP = '5033276/top'
export const GOOGLE_DATA_AD_SLOT_ID_SIDERIGHT = '5033276/sideright';
export const GOOGLE_DATA_AD_SLOT_ID_HORIZONTAL = '5033276/horizontal';

export const MAX_PRICE = 99999999;
export const MY_AREA_ITEMS_PER_PAGE = 20;

export const SUPERADMIN_NAME = 'Ron'
export const SWISS_CURRENCY = 'CHF'
export const HIGHLIGHT_PRICE = 25
export const JOBS_AD_PUBLISHING_COST = 48;
export const ONE_YEAR_PUBLICATION_PLAN_COST = 45;

export const PROFILE_PHOTO_CROP_HEIGHT = 1000;
export const PROFILE_PHOTO_CROP_WIDTH = 1000;
